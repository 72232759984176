import React from "react";

import { Icon } from '@types';

const SpotifyIcon: Icon = ({ fill = "white" }) => (
    <svg version="1.1"
        width="16"
        height="16"
        viewBox="0 0 16 16">
        <path fill={fill} d="M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.559-8-8-8zM11.681 11.559c-0.159 0.241-0.441 0.319-0.681 0.159-1.881-1.159-4.241-1.4-7.041-0.759-0.281 0.081-0.519-0.119-0.6-0.359-0.081-0.281 0.119-0.519 0.359-0.6 3.041-0.681 5.681-0.4 7.759 0.881 0.281 0.119 0.322 0.438 0.203 0.678zM12.641 9.359c-0.2 0.281-0.559 0.4-0.841 0.2-2.159-1.319-5.441-1.719-7.959-0.919-0.319 0.081-0.681-0.081-0.759-0.4-0.081-0.319 0.081-0.681 0.4-0.759 2.919-0.881 6.519-0.441 9 1.081 0.238 0.119 0.359 0.519 0.159 0.797zM12.719 7.119c-2.559-1.519-6.841-1.681-9.281-0.919-0.4 0.119-0.8-0.119-0.919-0.481-0.119-0.4 0.119-0.8 0.481-0.919 2.841-0.841 7.519-0.681 10.481 1.081 0.359 0.2 0.481 0.681 0.281 1.041-0.203 0.278-0.681 0.397-1.044 0.197z"></path>
    </svg>
);

export default SpotifyIcon;
