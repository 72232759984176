import React from "react";

import { Icon } from '@types';

const XingIcon: Icon = ({ fill = "white" }) => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 2.128906 2.765625 C 2.007812 2.765625 1.902344 2.808594 1.851562 2.890625 C 1.800781 2.976562 1.808594 3.085938 1.863281 3.199219 L 3.226562 5.5625 C 3.230469 5.566406 3.230469 5.570312 3.226562 5.574219 L 1.082031 9.363281 C 1.027344 9.472656 1.03125 9.585938 1.082031 9.671875 C 1.132812 9.753906 1.226562 9.808594 1.34375 9.808594 L 3.363281 9.808594 C 3.664062 9.808594 3.8125 9.601562 3.914062 9.417969 C 3.914062 9.417969 6.007812 5.707031 6.089844 5.5625 C 6.082031 5.546875 4.703125 3.140625 4.703125 3.140625 C 4.601562 2.964844 4.449219 2.765625 4.140625 2.765625 L 2.125 2.765625 Z M 2.128906 2.765625 "
            fill={fill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 10.609375 0 C 10.308594 0 10.175781 0.1875 10.066406 0.386719 C 10.066406 0.386719 5.71875 8.101562 5.574219 8.355469 C 5.582031 8.367188 8.445312 13.617188 8.445312 13.617188 C 8.546875 13.796875 8.699219 14.003906 9.007812 14.003906 L 11.023438 14.003906 C 11.144531 14.003906 11.242188 13.957031 11.292969 13.875 C 11.347656 13.789062 11.34375 13.675781 11.289062 13.5625 L 8.445312 8.359375 C 8.441406 8.355469 8.441406 8.351562 8.445312 8.347656 L 12.914062 0.441406 C 12.96875 0.328125 12.972656 0.21875 12.917969 0.128906 C 12.867188 0.046875 12.773438 0 12.652344 0 Z M 10.609375 0 "
            fill={fill}
        />
    </svg>
);

export default XingIcon;
